<template>
  <v-container>
    <v-card elevation="12" min-height="600">
      <v-card-title>Beni</v-card-title>
      <v-card-text>
        <v-form @submit.prevent="checkBeforeSend">
          <v-text-field
            label="Numero inventario"
            ref=myinput
            prefix="DipINFO-"
            v-model="inventario"
          ></v-text-field>
          <v-btn @click="checkBeforeSend">vai</v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
// @ is an alias to /src


export default {
  name: "Assets",
  data: () => {
    return {
      inventario: "",
      rules: [
        {
          type: "text",
          id: "lastname",
          label: "Cognome"
        },
        {
          type: "text",
          id: "firstname",
          label: "Nome",
        },
        {
          type: "number",
          id: "bo",
          label: "Bo",
        }
      ],
      query: {},
      selectedRule: 0,
      labels: {
        matchType: "Tipo ricerca",
        matchTypes: [
          { id: "all", label: "Tutti" },
          { id: "any", label: "Almeno uno" },
        ],
        addRule: "Aggiungi regola",
        removeRule: "&times;",
        addGroup: "Aggiungi gruppo",
        removeGroup: "&times;",
        textInputPlaceholder: "valore",
      },
    };
  },
  mounted() {
    // this.$refs.myinput.$el.click();
    this.$nextTick(() => this.$refs.myinput.$refs.input.focus());
  },
  methods: {
    checkBeforeSend() {
       if (this.inventario.includes('DipINFO-')) {
         this.inventario = this.inventario.replace('DipINFO-','')
       }
       this.$router.push('/assets/' + 'DipINFO-' + this.inventario);
    }
  }
};
</script>